import React, { useEffect, useState } from "react";



import AOS from "aos";
import "aos/dist/aos.css";
import service from "../../Service/Service";
import Footer from "../footer";

import BannerSection from "../Home3/BannerSection";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loader } from "react-feather";
import { Rocket } from "lucide-react";
import parse from 'html-react-parser';
import InputSection from "../Home3/InputSection";

const FlightDetail = (props) => {
  const [flights, setFlights] = useState([]);
  const [packagee, setPackage] = useState();
  const [packageData, setPackageData] = useState();
  const [loading, setLoading] = useState(false);
  const [paras, setParas] = useState([]);
  const [name,setName]=useState('');
    const [phone,setPhone]=useState('');
    const [email,setEmail]=useState('');
    const [toast,showToast]=useState(false)
    const [error,setError]=useState('')
    const [submitted,setSubmitted]=useState(false)
    const navigate=useNavigate()

  const [searchParams, setSearchParams] = useSearchParams();
 
  
    useEffect(()=>{
      
      const token = searchParams.get('package')
  console.log(token)//123
  if(!token){
    localStorage.clear();
    window.location.href='/'
    return;
  }

  
  setPackage(token)
  
    },[window.location])

    const getAllparas = (id) => {
        service.getAllparas().then((response) => {
          let dep = response.data.filter((x) => x.package_id == id);
          setParas(dep)
         
        });
      };

    useEffect(()=>{
      
    if(packagee){
       let pp={}
        if(packagee==0){
          let params=localStorage.getItem('booking');
          if(params){
            params=JSON.parse(params);

            console.log(params,'params')
            pp=params;
          }
        }
        setLoading(true)
        // service.postGetFlights({
        //     package:packagee,
        //     ...pp
        // }).then(ress=>{
        //     setLoading(false)
        //     console.log(ress.data.flights);
        //     setFlights(ress.data.flights)
        //     setPackageData(ress.data.package)
        //     getAllparas(ress.data.package.uid)
        // }).catch(err=>{
        //     setLoading(false)
        // })

        service.getSearchFlights({
              arrival:pp.arrival,
              departure:pp.departure
          }).then(ress=>{
              setLoading(false)
              console.log(ress.data.flights);
              setFlights(ress.data)
             // setPackageData(ress.data.package)
             // getAllparas(ress.data.package.uid)
             //document.getElementById("flightdetail").scrollIntoView();
             
          }).catch(err=>{
              setLoading(false)
           })
    }
  
    },[packagee])

    useEffect(()=>{
      if(!loading ){
        var scrollDiv = document.getElementById("flightdetail").offsetTop;
window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
      }

    },[loading,flights])

    const submit=(e)=>{
      e.preventDefault()
      let booking= localStorage.getItem('bookingresult')
      if(!booking){
        setError('Submission failed');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }

      booking=JSON.parse(booking);
      if(!name){
        setError('Please Enter Full Name');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      if(!phone){
        setError('Please Enter Phone');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      if(!email){
        setError('Please Enter Email');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      
      

        let params={
            
            full_name:name,
            phone,
           email,
           uid:booking.insertId

        }

        service.addBooking(params).then(x=>{
          // document.getElementById('my_modal_4').showModal()
          // if(setShowToast){
          //   setShowToast(true);
          // }

          localStorage.clear()
          setSubmitted(true)
         // navigate(`/`)
        }).catch(err=>{
          console.log(err);
        })
    }
 

  return (
    loading?<div className="absolute z-[4] bg-[rgba(0,0,0,0.9)] w-full h-[100vh] flex justify-center items-center gap-3">
    <Rocket className="text-white text-lg"/> <p className="text-white text-lg">Loading...</p>
</div>:
    <div className="relative">
        

      <BannerSection/>
      <div className=" flex justify-center items-center" id="flightdetail">
      </div>
      <div className=" flex justify-center items-center" >
        <p className="text-[40px] my-2 font-bold">Flights Detail</p>
      </div>

     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 w-[90%] md:w-[80%] mx-auto gap-2">

    

    {
         flights ? flights.map(f=>{
return(<div className="grid grid-cols-3 p-4 border border-mLightGray rounded-lg shadow-lg my-2 ">
  {
          toast && <div className="absolute z-[100]">
          <div className="toast toast-top toast-end">
    <div className="alert alert-info bg-[red]">
      <span className="text-white text-lg">{error}</span>
    </div>
          </div>
  
    
  </div>
        }
        
        <div className="col-span-3 flex gap-2 items-center">
        <p className="font-bold text-lg">{f.dep_iatacode+` - ${f.dep_name}`}</p>
        <p className=" text-lg">to</p>
        <p className="font-bold text-lg">{f.arr_iatacode+` - ${f.arr_name}`}</p>
    
    
        </div>
        <div className="col-span-full my-2">
          <hr className="text-gray"/>
        </div>

        <div className="flex flex-col  ">
    <p className="font-bold">Price</p>
        <p className="mt-1">{f.fare} (GBP)</p>
    </div>
 
    <div className="flex flex-col md:justify-center md:items-center">
        <p className="font-bold">AirLine</p>
        <div className="flex gap-2 items-center">
          <img src={service.baseURL+'/uploads/'+f.airline_flag} className="h-[40px] w-[40px]"/>
        <p>{f.airline}</p>
        </div>
    </div>
    <div  className="flex md:justify-end items-center mt-2 md:mt-0">

        <button className="btn rounded-xl bg-primary " onClick={()=>{
            document.getElementById('my_modal_flightdetail').showModal()
        }}>Book now</button>
    </div>
    {/* <div className="col-span-full w-full">
        <hr className="text-mLightGray mt-1"/>
        <div className="flex flex-col md:flex-row w-full">
            {
                f.itineraries[0].segments.map((s,index)=>{
                    return <div className="w-full md:w-[50%]">
                        <p className="text-mLightGray font-bold">Sengment:{index+1}</p>
                        <div className="grid grid-cols-4 gap-2">
                        <p className="text-mLightGray">Arrival: </p>
                        <p>{s.arrival.iataCode} - T:{s.arrival.terminal}</p>
                        <p className="text-mLightGray">Departure: </p>
                        <p>{s.departure.iataCode} - T:{s.departure.terminal}</p>
                        </div>
                    </div>
                })
            }
        </div>
    </div> */}
</div>)
        }):<p className="text-lg text-mLightGray">No Flights</p>
    }

</div>
<div className="my-[70px] w-[90%] md:w-[80%] mx-auto grid grid-cols-1">
        {paras.map((x) => {
          return parse(x.description)
        })}
      </div>
      
      <Footer />

      <dialog id="my_modal_flightdetail" className="modal">
  <div className="modal-box w-11/12 max-w-xl ">
    <form method="dialog">
      {!submitted && <div>
      <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 ">Full Name</label>
        <input type="text" placeholder="Type here" className="p-1 w-full  h-[40px] rounded-lg border" onChange={(e)=>{setName(e.target.value)}}/>
        </div>
        <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 ">Phone Number</label>
        <input type="Number" placeholder="Type here" className="p-1 w-full  h-[40px] rounded-lg border" onChange={(e)=>{setPhone(e.target.value)}}/>
        </div>
        <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 ">Email</label>
        <input type="text" placeholder="Type here" className="p-1 w-full  h-[40px] rounded-lg border" onChange={(e)=>{setEmail(e.target.value)}}/>
        </div>
        <button className="btn bg-primary w-full" onClick={submit}>Submit</button>
      </div>}
    
      {/* if there is a button in form, it will close the modal */}
      {!submitted &&<button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={()=>{}}>✕</button>}
    </form>
    {submitted && <h5 className="">Booking has been submitted, one of our agents will call you shortly.
    For quick response, please call: (020 4548 3242)</h5>}
    {submitted && <button className="btn bg-primary w-full mt-2" onClick={()=>{navigate('/')}}>Close</button>}
  </div>
</dialog>
    </div>
  );
};



export default FlightDetail;
