import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "./swiper.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/effect-fade";





import B1 from "../../assets/images/banner/b11.jpg";
import logo from "../../assets/images/logo.png";



import InputSection from "./InputSection";

import { Compass, CreditCard, MapPin, MonitorCog, PhoneCall, Share, Share2, Umbrella } from "lucide-react";
import { useNavigate } from "react-router-dom";

const BannerSection = (props) => {
 
    const navigate=useNavigate()
  useEffect(() => {
    
  
  }, []);

  return (
    <div>
      <div className="relative w-full  ">
        <div className="absolute z-[-1] bg-[rgba(0,0,0,0.6)] w-full h-full"></div>
        <BackSwiper />
        <div className="  w-full flex justify-center flex-col">
          {/* <p className="tracking-[3px] text-2xl md:text-4xl font-[600]  leading-12 mt-7 text-white text-center" role="button" onClick={()=>{navigate('/')}}>
            eDreamSales UK
          </p> */}
          <div className="flex flex-col md:flex-row w-[90%] md:w-[80%] mx-auto justify-center items-center md:justify-between mt-5">
          <img src={logo} className=" w-[200px] my-2" role="button" onClick={()=>{ window.location.href = '/'}}/>
          <div className="flex md:ml-auto items-center gap-4" role="button" onClick={()=>{ window.location.href = 'tel:02045483242'}}>
          <PhoneCall className="text-white"/>
          <p className="tracking-[2px] text-xl md:text-3xl font-[500] leading-8 text-center text-white blink-color">
          020 4548 3242
          </p>
          </div>
          </div>


          <div className="md:hidden p-2 mt-3">
              <p className="text-lg md:text-2xl text-white font-bold">Are you ready to travel</p>
              <p className="text-xl md:text-4xl text-white font-bold">ultimate <span className="text-[#22c55e]">Flight search!</span></p>
              <InputSection />
            </div>


          <div className="w-[90%] md:w-[80%] mx-auto grid grid-cols-1 md:grid-cols-2 gap-5 mt-8">
            <div className="hidden md:block">
              <p className="text-2xl text-white font-bold">Are you ready to travel</p>
              <p className="text-4xl text-white font-bold">ultimate <span className="text-[#22c55e]">Flight search!</span></p>
              <InputSection />
            </div>

            <div>
              

              <div className="w-full mt-7">
                  <div className="flex gap-4 items-center mb-1">
                  <div className="rounded-lg bg-[#22c55e] flex justify-center items-center h-[40px] w-[40px]  md:h-[45px] md:w-[45px] drop-shadow-md drop-shadow-white">
                  <Umbrella className="text-white"/>
                </div>
                <p className="text-xl md:text-2xl text-[#22c55e] font-bold">Financial Protection</p>
                  </div>
                 
                <p className="text-md md:text-lg text-white mt-2">Book with full confidence with us as all of our tickets protected.</p>
                </div>
              <hr className="text-white my-4"/>

              
                
                <div className="w-full">
                  <div className="flex gap-4 items-center mb-1">
                  <div className="rounded-lg bg-[#22c55e] flex justify-center items-center h-[40px] w-[40px]  md:h-[45px] md:w-[45px] drop-shadow-md drop-shadow-white">
                  <MonitorCog className="text-white"/>
                </div>
                <p className="text-xl md:text-2xl text-[#22c55e] font-bold">Book With Confidence</p>
                  </div>
                 
                <p className="text-md md:text-lg text-white mt-2">Best Fare Guarantee. We offer the best fare possible and we will beat the quote you have got. We always stay in touch with our customers for their better asistance.</p>
                </div>

              
              <hr className="text-white my-4"/>

              <div className="w-full">
                  <div className="flex gap-4 items-center mb-1">
                  <div className="rounded-lg bg-[#22c55e] flex justify-center items-center h-[40px] w-[40px]  md:h-[45px] md:w-[45px] drop-shadow-md drop-shadow-white">
                  <CreditCard className="text-white"/>
                </div>
                <p className="text-xl md:text-2xl text-[#22c55e] font-bold">Flexible Payment Options</p>
                  </div>
                 
                <p className="text-md md:text-lg text-white mt-2">Pay £50 as an initial payment and remaining balance later in installments without any extra cost. There are no hidden charges.</p>
                </div>

             

            </div>


            <div className="w-full justify-center items-center col-span-2">
          <p className="tracking-[3px] text-2xl font-[600]  leading-12 mt-0 text-white text-center">
          5 Star Rated Company
          </p>
          <p className="tracking-[2px] text-xl font-[500] leading-8 mt-2 text-white text-center ">
          We are the only 5 star rated company on google and facebook.
          </p>
          </div>


          </div>

         
        </div>
      </div>

    



    </div>
  );
};

const BackSwiper = () => {
  return (
    <div className="absolute z-[-2] w-full h-full ">
      <img src={B1} className="h-full w-full object-cover" />
     
    </div>
  );
};

export default BannerSection;
